<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<style lang="scss" scoped>
@import '~bootstrap-vue/dist/bootstrap-vue.css';
</style>

<script>
import { SET_MENU } from '@/core/services/store/modules/menu.module';
import localData from '../../../utils/saveDataToLocal';
import { DEFAULT_TITLE } from '../../../utils/constants';
import { SideBar } from '@/core/config/menu/sideBar';

export default {
  components: {},
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || DEFAULT_TITLE;
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_MENU, SideBar.BILL);
  },
  methods: {
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
